import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import createStore from '@/store'
import accessControl from '../middleware/accessControl';

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/LoginView.vue')
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   component: () => import('../views/AboutView.vue')
  // },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/app/DashboardApp.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/kardex',
    name: 'kardex',
    component: () => import('@/views/app/kardex/ItemKardexView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/ingresos-egresos',
    name: 'ingresos-egresos',
    component: () => import('@/views/app/kardex/IngresosEgresosView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/inventario',
    name: 'inventario',
    component: () => import('@/views/app/inventario/InventarioView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/item/:id',
    name: 'item',
    component: () => import('@/views/app/inventario/ItemInventarioView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/detailitem/:id',
    name: 'detailitem',
    component: () => import('@/views/app/inventario/ItemDetailView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/cambiarimpuestos',
    name: 'cambiarimpuestos',
    component: () => import('@/views/app/inventario/CambiarImpuestosView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/barcodegenerator',
    name: 'barcodegenerator',
    component: () => import('@/views/app/inventario/BarCodeGeneratorView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/proveedores',
    name: 'proveedores',
    component: () => import('@/views/app/proveedores/ProveedoresView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/proveedor/:id',
    name: 'proveedor',
    component: () => import('@/views/app/proveedores/ItemProveedorView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/app/UserProfile.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/configuration',
    name: 'configuration',
    component: () => import('../views/app/GeneralConfigurationView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/rol',
    name: 'rol',
    component: () => import('../views/app/roles/GestionRol.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/rol/asignar-ruta',
    name: 'rolRuta',
    component: () => import('../views/app/roles/AsignarRutas.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/users',
    name: 'users',
    component: () => import('../views/app/users/ManageUsers.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/users/form',
    name: 'usersForm',
    component: () => import('../views/app/users/FormView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/clientes',
    name: 'clientes',
    component: () => import('../views/app/clientes/ClientView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/clientes-stats/:id',
    name: 'clientes-stats',
    component: () => import('../views/app/clientes/StatsClientView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/cliente/:id',
    name: 'cliente',
    component: () => import('@/views/app/clientes/ItemClienteView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/prints',
    name: 'prints',
    component: () => import('../views/app/PrintsView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/stocks',
    name: 'stocks',
    component: () => import('../views/app/inventario/InventarioStockView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/categories',
    name: 'categories',
    component: () => import('../views/app/CategoriesView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/comprobantes',
    name: 'comprobantes',
    component: () => import('../views/app/comprobantes/ComprobantesView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/admin-comprobantes',
    name: 'admin-comprobantes',
    component: () => import('../views/app/comprobantes/AdminComprobantesView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/comprobante',
    name: 'comprobante',
    component: () => import('../views/app/comprobantes/NewComprobanteView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/recibo',
    name: 'recibo',
    component: () => import('../views/app/comprobantes/NewReciboView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/comprobantexml',
    name: 'comprobantexml',
    component: () => import('../views/app/comprobantes/ComprobanteXmlView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/loadcomprobantexml',
    name: 'loadcomprobantexml',
    component: () => import('../views/app/comprobantesxml/LoadXmlComprobanteView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/processcomprobantexml',
    name: 'processcomprobantexml',
    component: () => import('../views/app/comprobantesxml/ProcessXmlComprobanteView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/viewcomprobantexml/:id',
    name: 'viewcomprobantexml',
    component: () => import('../views/app/comprobantesxml/ViewXmlComprobanteView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/caja/gestionar-caja',
    name: 'gestionar-caja',
    component: () => import('../views/app/cajas/GestionarCajaView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/caja/detalle/:id',
    name: 'detalleCaja',
    component: () => import('../views/app/cajas/DetalleCajasView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/caja/egresos-caja',
    name: 'egresos-caja',
    component: () => import('../views/app/cajas/EgresosView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/caja/egreso-caja/:id',
    name: 'egreso-caja',
    component: () => import('../views/app/cajas/ItemEgresoView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/caja/detalle/transacciones/:id',
    name: 'detalleTransacciones',
    component: () => import('../views/app/cajas/DetalleTransaccionesView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/reportes-generales',
    name: 'reportes-generales',
    component: () => import('../views/app/reportes/ReportesGeneralesView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/reportes-manifiestos',
    name: 'reportes-manifiestos',
    component: () => import('../views/app/reportes/ReportesManifiestosView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/excelmanifiestos-reporte1',
    name: 'excelmanifiestos-reporte1',
    component: () => import('../views/app/reportes/ManifiestosExel2View.vue'),
    // component: () => import('../views/app/reportes/ManifiestosExel1View.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/excellogistica-reporte1',
    name: 'excellogistica-reporte1',
    component: () => import('../views/app/reportes/LogisticaExel1View.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/publicaciones',
    name: 'publicaciones',
    component: () => import('../views/app/catalogo/PublicacionesView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/publicacion/:id',
    name: 'publicacion',
    component: () => import('../views/app/catalogo/PublicacionView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/publicacion-pictures/:id',
    name: 'publicacion-pictures',
    component: () => import('../views/app/catalogo/PicturesPublicacionView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/notificaciones',
    name: 'notificaciones',
    component: () => import('../views/app/notificaciones/NotificationsView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/contratos',
    name: 'contratos',
    component: () => import('@/views/app/contratos/ContratosView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/contrato/:id',
    name: 'contrato',
    component: () => import('@/views/app/contratos/ItemContratoView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/detailcontrato/:id',
    name: 'detailcontrato',
    component: () => import('@/views/app/contratos/ItemDetailContratoView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/categories-contratos',
    name: 'categories-contratos',
    component: () => import('@/views/app/contratos/CategoriesContratosView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/tipos-referencias',
    name: 'tipos-referencias',
    component: () => import('@/views/app/contratos/TiposReferenciasView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/entidades',
    name: 'entidades',
    component: () => import('@/views/app/entidades/EntidadesView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/entidad/:id',
    name: 'entidad',
    component: () => import('@/views/app/entidades/ItemEntidadView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/receptores',
    name: 'receptores',
    component: () => import('@/views/app/receptores/ReceptoresView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/receptor/:id',
    name: 'receptor',
    component: () => import('@/views/app/receptores/ItemReceptorView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/operarios',
    name: 'operarios',
    component: () => import('@/views/app/receptores/OperariosView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/operario/:id',
    name: 'operario',
    component: () => import('@/views/app/receptores/FormOperarioView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/manifiestos',
    name: 'manifiestos',
    component: () => import('@/views/app/manifiestos/ManifiestosView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/manifiesto/:id',
    name: 'manifiesto',
    component: () => import('@/views/app/manifiestos/ItemManifiestoView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/manifiestorecords/:id',
    name: 'manifiestorecords',
    component: () => import('@/views/app/manifiestos/ItemManifiestoRecordsView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/manifiestoedit/:id',
    name: 'manifiestoedit',
    component: () => import('@/views/app/manifiestos/ItemManifiestoEditView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/componentes',
    name: 'componentes',
    component: () => import('@/views/app/manifiestos/ComponentesView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/vehiculos',
    name: 'vehiculos',
    component: () => import('@/views/app/vehiculos/VehiculosView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/vehiculo/:id',
    name: 'vehiculo',
    component: () => import('@/views/app/vehiculos/ItemVehiculoView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/conductores',
    name: 'conductores',
    component: () => import('@/views/app/choferes/ChoferesView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/conductor/:id',
    name: 'conductor',
    component: () => import('@/views/app/choferes/ItemChoferView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/rutas-recorrido',
    name: 'rutas-recorrido',
    component: () => import('@/views/app/rutasrecorrido/RutasRecorridoView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/ruta-recorrido/:id',
    name: 'ruta-recorrido',
    component: () => import('@/views/app/rutasrecorrido/ItemRutaRecorridoView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/empleados',
    name: 'empleados',
    component: () => import('@/views/app/rrhh/EmpleadosView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/empleado/:id',
    name: 'empleado',
    component: () => import('@/views/app/rrhh/FormEmpleadoView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/personas',
    name: 'personas',
    component: () => import('@/views/app/personas/PersonasView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/persona/:id',
    name: 'persona',
    component: () => import('@/views/app/personas/FormPersonaView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/cargos',
    name: 'cargos',
    component: () => import('@/views/app/rrhh/CargosView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    },
  },
  {
    path: '/registradores',
    name: 'registradores',
    component: () => import('@/views/app/entidades/RegistradoresView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/registrador/:id',
    name: 'registrador',
    component: () => import('@/views/app/entidades/FormRegistradorView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/motivos-anulacion',
    name: 'motivos-anulacion',
    component: () => import('@/views/app/manifiestos/MotivosAnulacionView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/motivos-inactividad',
    name: 'motivos-inactividad',
    component: () => import('@/views/app/contratos/MotivosInactividadView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  //REPORTES
  {
    path: '/excel-comprobantes',
    name: 'excel-comprobantes',
    component: () => import('../views/app/reports/ComprobantesExelView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
]

Vue.use(VueRouter)

const createRouter = () => new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const router = createRouter()

export function resetRouter () {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // the relevant part
}

// router.beforeEach(async (to, from) => {
//   console.log('direcciones to, from', to, from)
// })

router.beforeEach(async (to, from, next) => {
  let usuario = null;
  await createStore().then((store) => {
    usuario = store.getters.logeado;
  });
  let autorizacion = to.matched.some((record) => record.meta.autenticado);
  if (autorizacion && !usuario) {
    next("/");
  } else if (!autorizacion && usuario) {
    next("/dashboard");
  } else if (autorizacion && usuario) {
    if (to.meta.middleware) {
      const middleware = to.meta.middleware;
      middleware({ next, to });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router
